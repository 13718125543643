import React, { Component } from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default class ZMNavbar extends Component {

  renderAuthLinks() {
    const { authenticated, userIsAdmin } = this.props

    if (authenticated) {
      return (
        <>
          <Nav.Link as={Link} to="/admin/masters">Masters</Nav.Link>
          <Nav.Link as={Link} to="/account">Account</Nav.Link>
          {userIsAdmin ? <Nav.Link as={Link} to="/admin/tools">Tools</Nav.Link> : null}
          <Nav.Link as={Link} to="/logout">Logout</Nav.Link>
        </>
      )
    } else {
      return (
        <>
          {/* <Nav.Link as={Link} to="/login">Login</Nav.Link> */}
        </>
      )
    }
  }

  render() {
    return (
        <Navbar bg="white" expand="sm">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/search">Search</Nav.Link>
              <Nav.Link as={Link} to="/lineage-chart">Lineage Chart</Nav.Link>
              <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
              {this.renderAuthLinks()}
            </Nav>
          </Navbar.Collapse>
          <Nav>
            <Nav.Link as={Link} to="/search-info">?</Nav.Link>
          </Nav>
        </Navbar>
    )
  }
}
