import React, {useEffect, useState} from 'react'
import { getUsers as getUsersApiCall, editUserAdmin as editUserAdminApiCall, deleteUser as deleteUserApiCall } from '../config/api'
import { Row, Col, Container, FormCheck, Button, Stack } from 'react-bootstrap'

export default function AdminUsers(props) {
  const [users, setUsers] = useState(null)

  useEffect(() => {
    getUsers()
  },[])

  async function getUsers() {
    try {
      const res = await getUsersApiCall()
      res.data.sort((a, b) => (a.username < b.username ? -1 : 1))
      setUsers(res.data)
    } catch(err) {
      console.log(err)
    }
  }

  function changeAdmin(id) {
    return (e) => {
      editUserAdminApiCall({'user_id': id, 'admin': e.target.checked}).then(() => {
        getUsers()
      }).catch((err) => {
        console.log(err)
      })
    }
  }

  function deleteUser(id) {
    return (e) => {
      deleteUserApiCall(id).then(() => {
        getUsers()
      }).catch((err) => {
        console.log(err)
      })
    }
  }

  function renderUsersList() {
    if(users && users.length !== 0) {
      return users.map(user => (
        <Row>
          <Col sm={2}>{user.username}</Col>
          <Col sm={2}>{user.email}</Col>
          <Col sm={1}>
            <FormCheck type="switch" label="admin" defaultChecked={user.admin} disabled={user.id === props.userId} onChange={changeAdmin(user.id)} />
          </Col>
          <Col sm={1}>
            <Button variant="danger" disabled={user.id === props.userId} onClick={deleteUser(user.id)}>Delete</Button>
          </Col>
        </Row>
      ))
    }
  }


  return (
    <Container fluid>
      <h1 className="mb-0">Users</h1>
      <hr className='mt-0'/>
      <Container fluid>
        <Stack gap={3}>
          {renderUsersList()}
        </Stack>
      </Container>
    </Container>
  )
}
