import axios from 'axios'
import {finalConfig as config} from './config'

const searchElastic = async (query, indexes, showTop) => {
  const url = `${config.esEndpoint}/${indexes.join(',')}/_search?q=${encodeURI(query)}&size=${showTop}`

  const res = await axios({
    method: 'get',
    url: url
  })
  return res
}

const getElastic = async (id, index) => {
  const url = `${config.esEndpoint}/${index}/_doc/${id}`

  const res = await axios({
    method: 'get',
    url: url
  })
  return res
}

const saveText = async (id, index, editedName, editedText) => {
  const res = await axios({
    method: 'post',
    url: `/api/texts/${index}/${id}`,
    data: {
      name: editedName,
      text: editedText
    }
  })
  return res
}


export {
  searchElastic,
  getElastic,
  saveText
}