import React, { Component } from 'react'
import { Stack } from 'react-bootstrap'

export default class SearchInfo extends Component {
  render() {
    return (
      <Stack gap={3}>
        <div>
          <h1>Sources</h1>
          <p>The following texts were used to provide data for the search page. There's a lot more
          content in all of these texts including commentary by acknowledged Zen Masters and
          historical background. If you're interested in learning about Zen, they are all
          invaluable resources. Please consider supporting these authors and translators
          so that we might see more texts like these in the future!</p>
        </div>
        <div class="book-collection">
          <h2>Collections of Cases</h2>
          <div class="book">
            <span class="book-title">Mumonkan</span>{' '}
            <span class="translator">translated by R. H. Blyth</span>{' '}
            <span class="buy-it"><a href="https://www.amazon.com/Zen-Classics-Four-Mumonkan/dp/B01DYRYU4M">Buy on Amazon</a></span>
          </div>
          <div class="book">
            <span class="book-title">The Blue Cliff Record</span>{' '}
            <span class="translator">translated by Thomas Cleary and J. C. Cleary</span>{' '}
            <span class="buy-it"><a href="https://www.amazon.com/Blue-Cliff-Record-Thomas-Cleary/dp/159030232X">Buy on Amazon</a></span>
          </div>
          <div class="book">
            <span class="book-title">Book of Serenity</span>{' '}
            <span class="translator">translated by Thomas Cleary</span>{' '}
            <span class="buy-it"><a href="https://www.amazon.com/Book-Serenity-One-Hundred-Dialogues/dp/1590302494">Buy on Amazon</a></span>
          </div>
          <div class="book">
            <span class="book-title">The Treasury of the Eye of True Teaching</span>{' '}
            <span class="translator">translated by Thomas Cleary</span>{' '}
            <span class="buy-it"><a href="https://www.amazon.com/Treasury-Eye-True-Teaching-Discourses/dp/1645470784">Buy on Amazon</a></span>
          </div>
          <div class="book">
            <span class="book-title">The Measuring Tap</span>{' '}
            <span class="translator">translated by Thomas Cleary</span>{' '}
            <span class="buy-it"><a href="https://www.amazon.com.au/Measuring-Tap-Chan-Buddhist-Classic-ebook/dp/B07RYFN7JM">Buy on Amazon</a></span>
          </div>
          <div class="book">
            <span class="book-title">The Empty Valley Collection (volume 1)</span>{' '}
            <span class="translator">translated by Thomas Cleary</span>{' '}
            <span class="buy-it"><a href="https://www.amazon.com.au/Empty-Valley-Collection-one-ebook/dp/B01GP9ABGC">Buy on Amazon</a></span>
          </div>
        </div>
        <div class="book-collection">
          <h2>Sayings Texts</h2>
          <div class="book">
            <span class="book-title">Radical Zen: The Sayings of Zen Master Joshu</span>{' '}
            <span class="translator">translated by Yoel Hoffmann</span>{' '}
            <span class="buy-it"><a href="https://www.amazon.com/Radical-Zen-sayings-Zhaozhou-Congshen/dp/0394735129">Buy on Amazon</a></span>
          </div>
          <div class="book">
            <span class="book-title">The Sayings of Layman P'ang</span>{' '}
            <span class="translator">translated by James Green</span>{' '}
            <span class="buy-it"><a href="https://www.amazon.com/James-Green/dp/1590306309">Buy on Amazon</a></span>
          </div>
          <div class="book">
            <span class="book-title">Instant Zen</span>{' '}
            <span class="translator">translated by Thomas Cleary</span>{' '}
            <span class="buy-it"><a href="https://www.amazon.com/Instant-Zen-by-Foyen-Paperback/dp/B00LLORUYI">Buy on Amazon</a></span>
          </div>
          <div class="book">
            <span class="book-title">The Record of Tung-shan</span>{' '}
            <span class="translator">translated by William F. Powell</span>{' '}
            <span class="buy-it"><a href="https://www.amazon.com/Record-Tung-Shan-Classics-Asian-Buddhism/dp/0824810708">Buy on Amazon</a></span>
          </div>
          <div class="book">
            <span class="book-title">Zen Master Yunmen</span>{' '}
            <span class="translator">translated by Urs App</span>{' '}
            <span class="buy-it"><a href="https://www.amazon.com/Zen-Master-Yunmen-Essential-Sayings/dp/1611805597">Buy on Amazon</a></span>
          </div>
          <div class="book">
            <span class="book-title">Sun Face Buddha</span>{' '}
            <span class="translator">translated by Cheng Chien Bhikshu</span>{' '}
            <span class="buy-it"><a href="https://www.amazon.com/gp/product/0875730221">Buy on Amazon</a></span>
          </div>
              <div class="book">
            <span class="book-title">The Zen Teaching of Huang Po, On the Transmission of Mind</span>{' '}
            <span class="translator">translated by John Blofeld</span>{' '}
            <span class="buy-it"><a href="https://www.amazon.com/Zen-Teaching-Huang-Po-Transmission/dp/0802150926">Buy on Amazon</a></span>
          </div>
            <div class="book">
            <span class="book-title">The Zen Teachings of Master Lin-Chi</span>{' '}
            <span class="translator">translated by Burton Watson</span>{' '}
            <span class="buy-it"><a href="https://www.amazon.com/Zen-Teachings-Master-Lin-Chi/dp/0231114850">Buy on Amazon</a></span>
          </div>
          <div class="book">
            <span class="book-title">Sayings and Doings of Pai-chang</span>{' '}
            <span class="translator">translated by Thomas Cleary</span>{' '}
            <span class="buy-it"><a href="https://www.amazon.com.au/Sayings-Doings-Pai-chang-Zen-writings/dp/0916820106">Buy on Amazon</a></span>
          </div>
        </div>
      </Stack>
    )
  }
}