import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import {editUser as editUserApiCall, getCurrentUser as getCurrentUserApiCall} from '../config/api'

export default function Account(props) {
  const [userInfo, setUserInfo] = useState(null)
  const [toggle, setToggle] = useState({})

  useEffect(() => {
    getUserInfo()
  },[])

  function handleToggle(toggleKey) {
    setToggle({
      [toggleKey]: !toggle[toggleKey]
    })
  }
  
  async function getUserInfo() {
    try {
      const res = await getCurrentUserApiCall()
      if(res.status === 200) {
        setUserInfo(res.data)
      }
    } catch(err) {
      console.log(err, 'failed to get user info')
    }
  }

  async function editUserInfo({params, typeOfEdit, toggle}) {
    const {setAlertToggle, setAlertType, setAlertText} = props.alertSettings
    params.user_id = userInfo.id

    try {
      const res = await editUserApiCall(params)
      if(res.status === 200) {
        setAlertType('success')
        setAlertText(`Successfully updated ${typeOfEdit}`)
        setAlertToggle(true)
        setToggle({[toggle]: false})
        getUserInfo()
      } else throw new Error()
    } catch(err) {
      setAlertType('danger')
      setAlertText('Something Went Wrong, Please Try Again Later.')
      setAlertToggle(true)
    }
  }

  function editSingleItem({typeOfEdit, toggle, inputType}) {
    function handleEditSingleItem(event) {
      event.preventDefault()
      const data = event.target[typeOfEdit].value.trim()
      const params = {
        [typeOfEdit]: data
      }
      editUserInfo({params: params, typeOfEdit: typeOfEdit, toggle: toggle})
    }

    return(
      <Form className="d-flex flex-row" onSubmit={(event) => handleEditSingleItem(event)}>
        <Form.Group controlId={typeOfEdit}>
          <Form.Control 
            required
            size="sm"
            type={inputType}
            value={userInfo[typeOfEdit]}
            onChange={(event) => setUserInfo({...userInfo, [typeOfEdit]: event.target.value})}
          />
        </Form.Group>
        <Button type="submit" variant="link">Complete</Button>
      </Form>
    )
  }

  function renderUserInfo() {
    if(userInfo) {
      return(
        <Col sm={6}>
          <h1>Account</h1>
          <p>
            Username: {toggle["username"] ? editSingleItem({typeOfEdit: "username", toggle: "username", inputType: 'text'}) : userInfo.username} <Button onClick={() => handleToggle("username")} variant="link">{toggle["username"] ? '- Edit' : '+ Edit'}</Button>
          </p>
          <p>
            Email: {toggle["email"] ? editSingleItem({typeOfEdit: "email", toggle: "email", inputType: 'text'}) : userInfo.email} <Button onClick={() => handleToggle("email")} variant="link">{toggle["email"] ? '- Edit' : '+ Edit'}</Button>
          </p>
          <p>
            Email Confirmed: {userInfo.email_confirmed ? 'True' : 'False'}
          </p>
        </Col>
      )
    }
  }
  
  return (
    <Row>
      {renderUserInfo()}
    </Row>
  )
}
