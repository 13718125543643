import axios from 'axios'

const registerUser = async (params) => {
  const res = await axios({
    method: 'post',
    url: `/api/register`,
    data: params,
    validateStatus: (status) => status === 201 || status === 200 || status === 400
  })
  return res
}

const login = async (params) => {
  const res = await axios({
    method: 'post',
    url: `/api/login`,
    data: params
  })
  return res
}

const logout = async () => {
  const res = await axios({
    method: 'get',
    url: `/api/logout`,
  })
  return res
}

const getCurrentUser = async () => {
  const res = await axios({
    method: 'get',
    url: `/api/get_current_user`,
  })
  return res
}

const getUsers = async () => {
  const res = await axios({
    method: 'get',
    url: `/api/users`,
  })
  return res
}

const editUser = async (params) => {
  const res = await axios({
    method: 'post',
    url: `/api/edit_user`,
    data: params
  })
  return res
}

const editUserAdmin = async (params) => {
  const res = await axios({
    method: 'post',
    url: `/api/edit_user_admin`,
    data: params
  })
  return res
}

const deleteUser = async (id) => {
  const res = await axios({
    method: 'delete',
    url: `/api/delete_user/${id}`,
  })
  return res
}

const requestResetEmail = async (email) => {
  const res = await axios({
    method: 'post',
    url: `/api/request_reset_email`,
    data: {
      email: email
    }
  })
  return res
}

const resetPassword = async (password, token) => {
  const res = await axios({
    method: 'post',
    url: `/api/reset_password`,
    data: {
      password: password,
      token: token
    },
    validateStatus: (status) => status === 200 || status === 400
  })
  return res
}

const getMasters = async () => {
  const res = await axios({
    method: 'get',
    url: `/api/get_masters`
  })
  return res
}

const getMaster = async (master_id) => {
  const res = await axios({
    method: 'post',
    url: `/api/get_master`,
    data: {
      master_id: master_id
    }
  })
  return res
}

const addMaster = async (params) => {
  const res = await axios({
    method: 'post',
    url: `/api/add_master`,
    data: params
  })
  return res
}

const editMaster = async (params) => {
  const res = await axios({
    method: 'post',
    url: `/api/edit_master`,
    data: params
  })
  return res
}

export {
  registerUser,
  login,
  logout,
  getCurrentUser,
  getUsers,
  editUser,
  editUserAdmin,
  deleteUser,
  requestResetEmail,
  resetPassword,
  getMasters,
  getMaster,
  addMaster,
  editMaster
}