import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { logout as logoutAPICall } from '../config/api'

class Logout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      logoutMessage: ''
    }
  }

  componentDidMount() {
    logoutAPICall().then(() => {
      this.setState({
        logoutMessage: 'Logged out'
      })
      this.props.authenticate()
    }).catch((error) => {
      this.setState({
        logoutMessage: error.message
      })
    })
  }

  render() {
    return (
      <div>
        <h1>Logout</h1>
        <p>{this.state.logoutMessage}</p>
      </div>
    )
  }
}

export default withRouter(Logout)
