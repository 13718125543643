import React, { useState } from 'react'
import {Form, Button, Spinner} from 'react-bootstrap'
import Multiselect from 'multiselect-react-dropdown';
import MultiItemInput from './MultiItemInput';
import { addMaster as addMasterApiCall } from '../config/api';

export default function AddMaster(props) {
  const [displayName, setDisplayName] = useState('')
  const [originalChineseName, setOriginalChineseName] = useState('')
  const [generation, setGeneration] = useState('')
  const [dates, setDates] = useState('')
  const [school, setSchool] = useState('')
  const [biography, setBiography] = useState('')
  const [wadeGilesName, setWadeGilesName] = useState('')
  const [japaneseName, setJapaneseName] = useState('')
  const [alternativeNames, setAlternativeNames] = useState([])
  const [currentAlternativeName, setCurrentAlternativeName] = useState('')
  const [dharmaTeacher, setDharmaTeacher] = useState(null)
  const [otherTeachers, setOtherTeachers] = useState([])
  const [noteworthyStudents, setNoteworthyStudents] = useState([])
  const [bcrCases, setBcrCases] = useState([])
  const [currentBcrCase, setCurrentBcrCase] = useState('')
  const [bcrCommentaryCases, setBcrCommentaryCases] = useState([])
  const [currentBcrCommentaryCase, setCurrentBcrCommentaryCase] = useState('')
  const [bosCases, setBosCases] = useState([])
  const [currentBosCase, setCurrentBosCase] = useState('')
  const [bosCommentaryCases, setBosCommentaryCases] = useState([])
  const [currentBosCommentaryCase, setCurrentBosCommentaryCase] = useState('')
  const [wumenguan, setWumenguan] = useState([])
  const [currentWumenguan, setCurrentWumenguan] = useState('')
  const [totett, setTotett] = useState([])
  const [currentTotett, setCurrentTotett] = useState('')
  const [measuringTap, setMeasuringTap] = useState([])
  const [currentMeasuringTap, setCurrentMeasuringTap] = useState('')

  const {masters} = props

  async function handleAddMaster() {
    const {setAlertToggle, setAlertType, setAlertText} = props.alertSettings

    const params = {
      display_name: displayName,
      original_chinese_name: originalChineseName
    }
    if(generation !== '') params.generation = generation
    if(dates !== '') params.dates = dates
    if(school !== '') params.school = school
    if(wadeGilesName !== '') params.wade_giles_name = wadeGilesName
    if(japaneseName !== '') params.japanese_name = japaneseName
    if(alternativeNames.length !== 0) params.alternative_names = alternativeNames
    if(dharmaTeacher) params.dharma_teacher_id = dharmaTeacher
    if(otherTeachers.length !== 0) params.other_teachers = otherTeachers
    if(noteworthyStudents.length !== 0) params.noteworthy_students = noteworthyStudents
    if(bcrCases.length !== 0) params.bcr_cases = bcrCases
    if(bcrCommentaryCases.length !== 0) params.bcr_commentary_cases = bcrCommentaryCases
    if(bosCases.length !== 0) params.bos_cases = bosCases
    if(bosCommentaryCases.length !== 0) params.bos_commentary_cases = bosCommentaryCases
    if(wumenguan.length !== 0) params.wumenguan = wumenguan
    if(totett.length !== 0) params.totett = totett
    if(measuringTap.length !== 0) params.measuring_tap = measuringTap
    if(biography !== '') params.biography = biography

    try {
      const res = await addMasterApiCall(params)
      if(res.status === 201) {
        setAlertType('success')
        setAlertText('Master Succefully Added.')
        setAlertToggle(true)
        props.setAddMasterToggle(false)
      }
    } catch(err) {
      setAlertType('danger')
      setAlertText('Something Went Wrong, Please Try Again Later.')
      setAlertToggle(true)
    }
  }

  return (
    <div>
      <h4>Names:</h4>
      <>
        <Form.Group controlId="display_name">
          <Form.Label>Display Name</Form.Label>
          <small style={{color: 'orange'}}> *required</small>
          <Form.Control 
            required
            size="sm"
            type="text"
            placeholder="Display Name" 
            onChange={(event) => setDisplayName(event.target.value)}
          />
        </Form.Group>
        <br />
        <Form.Group controlId="original_chinese_name">
          <Form.Label>Original Chinese Name</Form.Label>
          <small style={{color: 'orange'}}> *required</small>
          <Form.Control 
            required
            size="sm" 
            type="text" 
            placeholder="Original Chinese Name"
            onChange={(event) => setOriginalChineseName(event.target.value)}
          />
        </Form.Group>
        <br />
        <Form.Group controlId="wade_giles_name">
          <Form.Label>Wade Giles Name</Form.Label>
          <Form.Control 
            size="sm"
            type="text"
            placeholder="Wade Giles Name" 
            onChange={(event) => setWadeGilesName(event.target.value)}
          />
        </Form.Group>
        <br />
        <Form.Group controlId="japanese_name">
          <Form.Label>Japanese Name</Form.Label>
          <Form.Control 
            size="sm"
            type="text"
            placeholder="Japanese Name" 
            onChange={(event) => setJapaneseName(event.target.value)}
          />
        </Form.Group>
        <br />
      </>
      <MultiItemInput 
        hasLabel={true}
        label='Alternative Name'
        values={alternativeNames}
        currentValue={currentAlternativeName}
        setValues={setAlternativeNames}
        setCurrentValue={setCurrentAlternativeName}
      />
      <br />
      <h4>Other Info:</h4>
      <>
        <Form.Group controlId="generation">
          <Form.Label>Generation</Form.Label>
          <Form.Control 
            size="sm"
            type="number"
            placeholder="Generation" 
            onChange={(event) => setGeneration(event.target.value)}
          />
        </Form.Group>
        <br />
        <Form.Group controlId="dates">
          <Form.Label>Dates</Form.Label>
          <Form.Control 
            size="sm"
            type="text"
            placeholder="Dates" 
            onChange={(event) => setDates(event.target.value)}
          />
        </Form.Group>
        <br />
        <Form.Group controlId="school">
          <Form.Label>School</Form.Label>
          <Form.Control 
            size="sm"
            type="text"
            placeholder="School" 
            onChange={(event) => setSchool(event.target.value)}
          />
        </Form.Group>
        <br />
        <Form.Group controlId="biography">
          <Form.Label>Biography</Form.Label>
          <Form.Control 
            size="sm"
            as="textarea"
            rows={10}
            placeholder="Biography..." 
            onChange={(event) => setBiography(event.target.value)}
          />
        </Form.Group>
      </>
      <br />
      <h4>Teachers/Students:</h4>
      {
        masters ?
        <Multiselect
          options={masters}
          onSelect={(selectedList, selectedItem) => setDharmaTeacher(selectedItem.id)}
          displayValue='display_name'
          placeholder='Dharma Teacher'
          selectionLimit={1}
        /> : <Spinner animation="grow" />
      }
      <br />
      {
        masters ?
        <Multiselect
          options={masters}
          onSelect={(selectedList, selectedItem) => setOtherTeachers(selectedList)}
          displayValue='display_name'
          placeholder='Other Teachers'
        /> : <Spinner animation="grow" />
      }
      <br />
      {
        masters ?
        <Multiselect
          options={masters}
          onSelect={(selectedList, selectedItem) => setNoteworthyStudents(selectedList)}
          displayValue='display_name'
          placeholder='Noteworthy Students'
        /> : <Spinner animation="grow" />
      }
      <br />
      <h4>BCR:</h4>
      <MultiItemInput 
        hasLabel={true}
        label='BCR Cases'
        type='number'
        values={bcrCases}
        currentValue={currentBcrCase}
        setValues={setBcrCases}
        setCurrentValue={setCurrentBcrCase}
      />
      <MultiItemInput 
        hasLabel={true}
        label='BCR Commentary Cases'
        type='number'
        values={bcrCommentaryCases}
        currentValue={currentBcrCommentaryCase}
        setValues={setBcrCommentaryCases}
        setCurrentValue={setCurrentBcrCommentaryCase}
      />
      <br />
      <h4>BOS:</h4>
      <MultiItemInput 
        hasLabel={true}
        label='BOS Cases'
        type='number'
        values={bosCases}
        currentValue={currentBosCase}
        setValues={setBosCases}
        setCurrentValue={setCurrentBosCase}
      />
      <MultiItemInput 
        hasLabel={true}
        label='BOS Commentary Cases'
        type='number'
        values={bosCommentaryCases}
        currentValue={currentBosCommentaryCase}
        setValues={setBosCommentaryCases}
        setCurrentValue={setCurrentBosCommentaryCase}
      />
      <br />
      <h4>Other Texts:</h4>
      <MultiItemInput 
        hasLabel={true}
        label='Wumenguan'
        type='number'
        values={wumenguan}
        currentValue={currentWumenguan}
        setValues={setWumenguan}
        setCurrentValue={setCurrentWumenguan}
      />
      <MultiItemInput 
        hasLabel={true}
        label='Treasury of the Eye of True Teaching'
        type='number'
        values={totett}
        currentValue={currentTotett}
        setValues={setTotett}
        setCurrentValue={setCurrentTotett}
      />
      <MultiItemInput 
        hasLabel={true}
        label='Measuring Tap'
        type='number'
        values={measuringTap}
        currentValue={currentMeasuringTap}
        setValues={setMeasuringTap}
        setCurrentValue={setCurrentMeasuringTap}
      />
      <br />
      <Button className="mt-5" variant="outline-secondary" onClick={() => handleAddMaster()}>
        Add Master
      </Button>
      <hr />
    </div>
  )
}
