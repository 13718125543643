import React from 'react';
import { useParams } from "react-router-dom";  
import { Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { resetPassword as resetPasswordAPICall } from '../config/api';

export default function ResetPassword(props) {
  const {token} = useParams()

  async function handleSubmit(event) {
    event.preventDefault()
    const password = event.target.password.value

    const {setAlertToggle, setAlertType, setAlertText} = props.alertSettings

    try {
      const res = await resetPasswordAPICall(password, token)
      if(res.status === 200) {
        setAlertType('success')
        setAlertText('Password Has Been Reset!')
        setAlertToggle(true)
      } else {
        setAlertType('warning')
        setAlertText('Your request has expired or is invalid.')
        setAlertToggle(true)
      }
    } catch (error) {
      setAlertType('danger')
      setAlertText('Something Went Wrong, Please Try Again Later.')
      setAlertToggle(true)
    }
    
  }

  return (
    <Row>
      <Col sm={6}>
        <h2 className="pb-3">Reset Password</h2>
        <Form onSubmit={(event) => handleSubmit(event)}>
        <Form.Group controlId="password">
            <Form.Label>Enter your new password</Form.Label>
            <Form.Control 
              required
              size="md"
              type="password"
              placeholder="New Password" />
          </Form.Group>
          <br />
          <Button variant="outline-secondary" type="submit">
            Reset
          </Button>
        </Form>
      </Col>
    </Row>
  )
}
