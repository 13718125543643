import React, { useEffect, useState } from 'react'
import {Button, Spinner, Row, Col} from 'react-bootstrap'
import { getMasters as getMastersApiCall } from '../config/api'
import AddMaster from '../components/AddMaster';
import { Link } from 'react-router-dom';

export default function AdminTools(props) {
  const [masters, setMasters] = useState(null)
  const [addMasterToggle, setAddMasterToggle] = useState(false)

  useEffect(() => {
    getMasters()
  },[])

  async function getMasters() {
    try {
      const res = await getMastersApiCall()
      setMasters(res.data)
    } catch(err) {
      console.log(err)
    }
  }

  return (
    <Row>
      <Col sm={6}>
        <h1 className='mb-0'>Admin Tools</h1>
        <hr className='mt-0'/>
        <Button as={Link} to={'/admin/masters'} variant="link">Masters List</Button>
        <br />
        <Button variant="link" onClick={() => setAddMasterToggle(!addMasterToggle)}>{addMasterToggle ? '- Master' : '+ Master'}</Button>
        {
          addMasterToggle ?
            <AddMaster masters={masters} alertSettings={props.alertSettings} setAddMasterToggle={(state) => setAddMasterToggle(state)}/> :
            null
        }
        <br />
        <Button as={Link} to={'/admin/users'} variant="link">Users List</Button>
      </Col>
    </Row>
  )
}
