import React, { Component } from 'react'
import {Form, Button} from 'react-bootstrap'
import {login as loginAPICall} from '../config/api'
import {withRouter, Link} from 'react-router-dom';
import { Spinner, Row, Col } from 'react-bootstrap';

class Login extends Component {
  
  componentDidUpdate() {
    if(this.props.authenticated) {
      this.props.history.push('/admin/tools')
    }
  }

  async handleSubmit(event) {
    event.preventDefault()
    const {setAlertToggle, setAlertType, setAlertText} = this.props.alertSettings
    const username_or_email = event.target.username_or_email.value.trim()
    const password = event.target.password.value.trim()
    const params = {
      username_or_email: username_or_email,
      password: password
    }
    try {
      const res = await loginAPICall(params)
      if(res.status === 200) {
        this.props.history.push('/account')
        this.props.authenticate()
        setAlertType("success")
        setAlertText(`Welcome back ${username_or_email}`)
        setAlertToggle(true)
      }
    } catch(err) {
      if(err.response && err.response.status === 400) {
        setAlertType("warning")
        setAlertText("Invalid Username/Email or Password.")
        setAlertToggle(true)
      } else {
        setAlertType("danger")
        setAlertText("Something went wrong, please try again.")
        setAlertToggle(true)
      }
    }
  }

  renderLogin(){
    const {authenticated, loadingAuth} = this.props
    if(loadingAuth){
      return <Spinner animation="border" role="status" />
    } else {
      if(!authenticated){
        return(
          <Row>
            <Col sm={6}>
              <h2 className="pb-3">Login</h2>
              <div>
                <Form onSubmit={(event) => this.handleSubmit(event)}>
                  <Form.Group controlId="username_or_email">
                    <Form.Label>Username or Email</Form.Label>
                    <Form.Control 
                      required
                      size="md"
                      type="text"
                      placeholder="Username or Email" />
                  </Form.Group>

                  <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control 
                      required
                      size="md" 
                      type="password" 
                      placeholder="Password"
                    />
                    <Link to="/forgot_password"><small>Forgot Password?</small></Link>
                  </Form.Group>
                  <br />
                  <Button variant="outline-secondary" type="submit">
                    Login
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        )
      }
      else {
        return(
          <div>
            <h2 className="pb-3 text-center">Login</h2>
            <p className="text-center"> already logged in</p>
          </div>
        )
      }
    }
  }

  render() {
    return this.renderLogin()
  }
}

export default withRouter(Login)