const caseTexts = ['bcr', 'mmk', 'bos', 'sho', 'mt', 'ev']
const sayingsTexts = ['zz', 'pang', 'foyan', 'dong', 'yun', 'ma', 'hb', 'lin', 'bzhang']
const allTexts = caseTexts.concat(sayingsTexts)

function sourceTextTitle(index) {
  switch(index) {
    case 'bcr': return 'Blue Cliff Record'
    case 'mmk': return 'Gateless Gate'
    case 'bos': return 'Book of Serenity'
    case 'sho': return 'Treasury of the Eye of True Teaching'
    case 'mt': return 'The Measuring Tap'
    case 'ev': return 'The Empty Valley Collection'
    case 'zz': return 'Sayings of Joshu'
    case 'pang': return 'Sayings of Layman P\'ang'
    case 'foyan': return 'Instant Zen (Foyan)'
    case 'dong': return 'The Record of Tung-shan (Dongshan)'
    case 'yun': return 'Zen Master Yunmen'
    case 'ma': return 'Sun Face Buddha (Master Ma)'
    case 'hb': return 'On the Transmission of Mind (Huangbo)'
    case 'lin': return 'The Zen Teachings of Lin-Chi (Linji)'
    case 'bzhang': return 'Sayings and Doings of Pai-chang (Baizhang)'
    default: return 'Unknown'
  }
}

export {
  caseTexts,
  sayingsTexts,
  allTexts,

  sourceTextTitle
}