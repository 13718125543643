import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import SingleResult from '../components/SingleResult'
import { searchElastic as searchElasticApiCall} from '../config/searchApi'
import { sourceTextTitle } from '../config/sourceTextUtils'

function arraysEqualUnordered(arr1, arr2) {
  if(!arr1 && !arr2) return true
  if(!arr1 || !arr2) return false
  if(arr1.length !== arr2.length) return false
  for(let i = 0; i < arr1.length; i++) {
    if(!arr2.includes(arr1[i])) return false
  }
  return true
}

export default class SearchResults extends Component {
  constructor(props) {
    super(props)

    this.state = {
      results: null,
      error: null,
      searching: false
    }

    if(this.props.queryText) {
      this.search()
    }
  }

  toggleItemCollapsed = (i) => {
    this.setState((state, props) => {
      state.results[i].collapsed = !state.results[i].collapsed
      return state
    })
  }

  search = () => {
    this.setState({searching: true})
    let res = searchElasticApiCall(this.props.queryText, this.props.selectedTexts, this.props.showTop)

    res
      .then(data => {
        this.setState({
          results: data.data.hits.hits.map((item, i) => {
            return {
              index: item._index,
              id: item._id,
              name: item._source.name,
              case: item._source.case,
              title: sourceTextTitle(item._index) + ' #' + item._id + (item._id === item._source.name ? '' : ': ' + item._source.name),
              collapsed: true,
              i: i
            }
          }),
          error: null,
          searching: false
        })
      })
      .catch(error => {
        this.setState({
          results: null,
          error: error,
          searching: false
        })
      })
  }

  componentDidUpdate(oldProps) {
    if(!this.props.queryText) {
      return
    }

    if(this.props.queryText !== oldProps.queryText ||
      this.props.showTop !== oldProps.showTop ||
      !arraysEqualUnordered(this.props.selectedTexts, oldProps.selectedTexts)) 
    {
      this.search()
    }
  }

  render() {
    if(this.state.error) {
      return (<Row><Col className="text-center">{this.state.error.message}</Col></Row>)
    }
    if(this.state.searching) {
      return (<Row><Col className="text-center">Searching...</Col></Row>)
    }
    if(this.props.queryText && this.state.results) {
      if(this.state.results.length === 0) {
        return (<Row><Col className="text-center">No Results</Col></Row>)
      }

      return this.state.results.map(item => <SingleResult item={item} queryText={this.props.queryText} toggleItemCollapsed={this.toggleItemCollapsed} />);
    }

    return (<></>)
  }
}
