import {
  BrowserRouter as Router,
} from "react-router-dom";
import Root from "./Root";

function App() {
  return (
    <Router>
      <Root />
    </Router>
  );
}

export default App;
