import React, { useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Form, Button, Col, Row } from 'react-bootstrap';
import {registerUser as registerUserAPICall} from '../config/api'

export default function Register(props) {
  const [warningTextUsername, setWarningTextUsername] = useState('')
  const [warningTextPassword, setWarningTextPassword] = useState('')
  const [warningUsernameToggle, setWarningUsernameToggle] = useState(false)
  const [warningPasswordToggle, setWarningPasswordToggle] = useState(false)

  const history = useHistory()

  function renderWarningTextUsername() {
    if(warningUsernameToggle === true) {
      return(
        <Form.Text style={{color: 'red'}}>
          {warningTextUsername}
        </Form.Text>
      )
    }
  }

  function renderWarningTextPassword() {
    if(warningPasswordToggle === true) {
      return(
        <Form.Text style={{color: 'red'}}>
          {warningTextPassword}
        </Form.Text>
      )
    }
  }

  async function handleSubmit(event) {
    event.preventDefault()
    const {setAlertToggle, setAlertType, setAlertText} = props.alertSettings

    const username = event.target.username.value.trim()
    const email = event.target.email.value.trim()
    const password = event.target.password.value.trim()
    const params = {
      username: username,
      email: email,
      password: password
    }
    if(!warningPasswordToggle && !warningUsernameToggle) {
      try {
        const res = await registerUserAPICall(params)
        console.log(res)
        if(res.status === 201) {
          setAlertToggle(true)
          setAlertType('success')
          setAlertText('Successful registration!')
          history.push("/login")
        }
        else if(res.status === 400) {
          setAlertToggle(true)
          setAlertType('warning')
          setAlertText('Email or Username is already in use.')
        }
      } catch (error) {
        console.log(error.response)
          setAlertToggle(true)
          setAlertType('danger')
          setAlertText('Something went wrong! Please try again later.')
      }
    } else {
      setAlertToggle(true)
      setAlertText('Please fix errors before you register.')
    }

  }

  function handleUsername(event) {
    const username = event.target.value
    if(username.length >= 20) {
      setWarningUsernameToggle(true)
      setWarningTextUsername('Too Long!')
    } else {
      setWarningUsernameToggle(false)
    }
  }

  function handlePassword(event) {
    const password = event.target.value
    if(password.length > 20) {
      setWarningPasswordToggle(true)
      setWarningTextPassword('Too Long!')
    } else if(password.length < 8 && password.length >= 1) {
      setWarningPasswordToggle(true)
      setWarningTextPassword('Too Short!')
    } else {
      setWarningPasswordToggle(false)
    }
  }

  return (
    <Row>
      <Col sm={6}>
        <h1 className="pb-2">Register</h1>
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Form.Group controlId="username">
            <Form.Label>Username</Form.Label>
            <Form.Control 
              required
              onChange={(event) => handleUsername(event)}
              size="md" 
              type="text" 
              placeholder="Username" />
            {renderWarningTextUsername()}
            <Form.Text id="passwordHelpBlock" muted>
                Your username must be shorter than 20 characters.
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control 
              required
              size="md"
              type="email"
              placeholder="Enter email" />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control 
              required
              onChange={(event) => handlePassword(event)}
              size="md" 
              type="password" 
              placeholder="Password" 
              aria-describedby="passwordHelpBlock" />
            {renderWarningTextPassword()}
            <Form.Text id="passwordHelpBlock" muted>
              Your password must be 8-20 characters long.
            </Form.Text>
          </Form.Group>
          <br />
          <Button variant="outline-secondary" type="submit">
            Sign Up
          </Button>
        </Form>
        <br />
        <div className="d-flex flex-column">
          <small>Aready a Member?</small>
          <Link to="/login">Log In</Link>
        </div>
      </Col>
    </Row>
  )
}