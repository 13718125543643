import React, {useEffect, useState} from 'react'
import { getMasters as getMastersApiCall } from '../config/api'
import { Button, Row, Col, Container } from 'react-bootstrap'
import {Link} from 'react-router-dom';

export default function Masters(props) {
  const [masters, setMasters] = useState(null)

  useEffect(() => {
    getMasters()
  },[])

  async function getMasters() {
    try {
      const res = await getMastersApiCall()
      res.data.sort((a, b) => (a.display_name < b.display_name ? -1 : 1))
      setMasters(res.data)
    } catch(err) {
      console.log(err)
    }
  }

  function renderMastersList() {
    if(masters && masters.length !== 0) {
      return masters.map(master => (
        <Row>
          <Col sm={2}>{master.display_name}</Col>
          <Col sm={2}>{master.original_chinese_name}</Col>
          <Col sm={1}>
            {props.userIsAdmin ? <Button as={Link} to={`/admin/edit/${master.id}`} variant="link">Edit</Button> : null}
          </Col>
        </Row>
      ))
    }
  }


  return (
    <Container fluid>
      <h1 className="mb-0">Zen Masters</h1>
      <hr className='mt-0'/>
      <Container fluid>
        {renderMastersList()}
      </Container>
    </Container>
  )
}
