import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function escapeHtml(unsafe) {
  return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;")
}

function processText(text, queryText) {
  // Tokenize the search string for correct highlighting
  var tokenRegexp = /[^\s"]+|"([^"]*)"/gi;
  var tokenArray = [];

  do {
      //Each call to exec returns the next regex match as an array
      var match = tokenRegexp.exec(queryText);
      if (match != null)
      {
          //Index 1 in the array is the captured group if it exists
          //Index 0 is the matched text, which we use if no captured group exists
          tokenArray.push(match[1] ? match[1] : match[0]);
      }
  } while (match != null);

  var markRegex = new RegExp("((<[^>]+>)*" + tokenArray.join('|') + "(<[^>]+>)*)", "gi");
  var newlineRegex = new RegExp("\\n", "g");
  var itStartRegex = new RegExp("^\\*", "gm");
  var itEndRegex = new RegExp("\\*$", "gm");

  let fixedText = escapeHtml(text).replace(itStartRegex, "<i>");
  fixedText = fixedText.replace(itEndRegex, "</i>");
  fixedText = "<p>" + fixedText + "</p>";
  fixedText = fixedText.replace(newlineRegex, "</p><p>");
  fixedText = fixedText.replace(markRegex, "<mark>$1</mark>");
  fixedText = fixedText.replace(/(<mark>[^<>]*)((<[^>]+>)+)([^<>]*<\/mark>)/, "$1</mark>$2<mark>$4");
  return fixedText;
}

export default class SingleResult extends Component {
  render() {
    const { item, queryText } = this.props;
    return (
      <Row className="justify-content-center">
        <Col sm={10}>
          <div className="border rounded">
            <Row className="justify-content-center">
              {this.props.toggleItemCollapsed ? 
                <>
                  <Col xs="auto"><Link to={`/single?id=${item.id}&index=${item.index}`}><i class="fas fa-link case-link"></i></Link></Col>
                  <Col ><h5 className="text-center case-title" onClick={() => this.props.toggleItemCollapsed(item.i)}>{item.title}</h5></Col>
                  <Col xs="auto" onClick={() => this.props.toggleItemCollapsed(item.i)}><i class="fas fa-plus case-expand" style={{float: 'right'}}></i></Col>
                </>
              : <Col xs={12}><h5 className="text-center case-title">{item.title}</h5></Col>}
            </Row>
            {item.collapsed ? null : <div className="text-center case-text" dangerouslySetInnerHTML={{__html: processText(item.case, queryText)}} />}          
          </div>
        </Col>
      </Row>
    )    
  }
}