import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getMaster as getMasterApiCall, editMaster as editMasterApiCall, getMasters as getMastersApiCall } from '../config/api'
import MultiItemInput from '../components/MultiItemInput'
import Multiselect from 'multiselect-react-dropdown'
import {Button, Form, Row, Col} from 'react-bootstrap'

export default function EditMaster(props) {
  const params = useParams()
  const [master, setMaster] = useState(null)
  const [masters, setMasters] = useState(null)
  const [alternativeNames, setAlternativeNames] = useState([])
  const [currentAlternativeName, setCurrentAlternativeName] = useState('')
  const [bcrCases, setBcrCases] = useState([])
  const [currentBcrCase, setCurrentBcrCase] = useState('')
  const [bcrCommentaryCases, setBcrCommentaryCases] = useState([])
  const [currentBcrCommentaryCase, setCurrentBcrCommentaryCase] = useState('')
  const [bosCases, setBosCases] = useState([])
  const [currentBosCase, setCurrentBosCase] = useState('')
  const [bosCommentaryCases, setBosCommentaryCases] = useState([])
  const [currentBosCommentaryCase, setCurrentBosCommentaryCase] = useState('')
  const [dharmaTeacher, setDharmaTeacher] = useState(null)
  const [otherTeachers, setOtherTeachers] = useState([])
  const [noteworthyStudents, setNoteworthyStudents] = useState([])
  const [wumenguan, setWumenguan] = useState([])
  const [currentWumenguan, setCurrentWumenguan] = useState('')
  const [totett, setTotett] = useState([])
  const [currentTotett, setCurrentTotett] = useState('')
  const [measuringTap, setMeasuringTap] = useState([])
  const [currentMeasuringTap, setCurrentMeasuringTap] = useState('')
  const [toggle, setToggle] = useState({})

  useEffect(() => {
    getMaster()
    getMasters()
  },[])

  async function getMasters() {
    try {
      const res = await getMastersApiCall()
      setMasters(res.data)
    } catch(err) {
      console.log(err)
    }
  }

  async function getMaster() {
    const masterId = params.master
    try {
      const res = await getMasterApiCall(masterId)
      setMaster({...res.data})
      if(res.data.dharma_teacher_id && res.data.dharma_teacher_display_name) setDharmaTeacher([{display_name: res.data.dharma_teacher_display_name, id: res.data.dharma_teacher_id}])
      if(res.data.alternative_names) setAlternativeNames([...res.data.alternative_names])
      if(res.data.bcr_cases) setBcrCases([...res.data.bcr_cases])
      if(res.data.bcr_commentary_cases) setBcrCommentaryCases([...res.data.bcr_commentary_cases])
      if(res.data.bos_cases) setBosCases([...res.data.bos_cases])
      if(res.data.bos_commentary_cases) setBosCommentaryCases([...res.data.bos_commentary_cases])
      if(res.data.other_teachers) setOtherTeachers([...res.data.other_teachers])
      if(res.data.noteworthy_students) setNoteworthyStudents([...res.data.noteworthy_students])
      if(res.data.wumenguan) setWumenguan([...res.data.wumenguan])
      if(res.data.totett) setTotett([...res.data.totett])
      if(res.data.measuring_tap) setMeasuringTap([...res.data.measuring_tap])
    } catch(err) {
      console.log(err)
    }
  }

  function handleToggle(toggleKey) {
    setToggle({
      [toggleKey]: !toggle[toggleKey]
    })
  }

  async function editMaster({params, typeOfEdit, toggle}) {
    const {setAlertToggle, setAlertType, setAlertText} = props.alertSettings
    params.master_id = master.id

    try {
      const res = await editMasterApiCall(params)
      if(res.status === 200) {
        setAlertType('success')
        setAlertText(`Successfully updated ${typeOfEdit}`)
        setAlertToggle(true)
        setToggle({[toggle]: false})
        getMaster()
      } else throw new Error()
    } catch(err) {
      setAlertType('danger')
      setAlertText('Something Went Wrong, Please Try Again Later.')
      setAlertToggle(true)
    }
  }

  function editSingleItem({typeOfEdit, toggle, inputType}) {
    function handleEditSingleItem(event) {
      event.preventDefault()
      const data = event.target[typeOfEdit].value.trim()
      const params = {
        [typeOfEdit]: data
      }
      editMaster({params: params, typeOfEdit: typeOfEdit, toggle: toggle})
    }

    if(inputType === 'textarea') {
      return(
        <Form className="d-flex flex-row" onSubmit={(event) => handleEditSingleItem(event)}>
          <Form.Group controlId={typeOfEdit}>
            <Form.Control 
              required
              as={inputType}
              rows={10}
              cols={80}
              size="sm"
              value={master[typeOfEdit]}
              onChange={(event) => setMaster({...master, [typeOfEdit]: event.target.value})}
            />
          </Form.Group>
          <Button type="submit" variant="link">Complete</Button>
        </Form>
      )
    } else return(
      <Form className="d-flex flex-row" onSubmit={(event) => handleEditSingleItem(event)}>
        <Form.Group controlId={typeOfEdit}>
          <Form.Control 
            required
            size="sm"
            type={inputType}
            value={master[typeOfEdit]}
            onChange={(event) => setMaster({...master, [typeOfEdit]: event.target.value})}
          />
        </Form.Group>
        <Button type="submit" variant="link">Complete</Button>
      </Form>
    )
  }

  function editArrayItem({typeOfEdit, toggle, label, values, currentValue, setValues, setCurrentValue}) {
    function handleEditArrayItem(event) {
      event.preventDefault()
      const params = {
        [typeOfEdit]: values
      }
      editMaster({params: params, typeOfEdit: typeOfEdit, toggle: toggle})
    }
    return(
      <div className='d-flex flex-row align-items-end'>
        <MultiItemInput 
          hasLabel={false}
          label={label}
          values={values}
          currentValue={currentValue}
          setValues={setValues}
          setCurrentValue={setCurrentValue}
        />
        <Button onClick={(event => handleEditArrayItem(event))} variant="link">Complete</Button>
      </div>
    )
  }

  function renderAlternativeNamesOrEdit() {
    if(toggle["alternativeNames"]) {
      return editArrayItem({
        typeOfEdit: "alternative_names",
        toggle: "alternativeNames",
        label: 'Alternative Name',
        values: alternativeNames,
        currentValue: currentAlternativeName,
        setValues: setAlternativeNames,
        setCurrentValue: setCurrentAlternativeName
      })
    }
    else if(master.alternative_names && !toggle["alternativeNames"]) {
      return master.alternative_names.map(name => `${name}, `)
    } else return 'None'
  }

  function renderBcrCasesOrEdit() {
    if(toggle["bcrCases"]) {
      return editArrayItem({
        typeOfEdit: "bcr_cases",
        toggle: "bcrCases",
        label: 'BCR Case',
        values: bcrCases,
        currentValue: currentBcrCase,
        setValues: setBcrCases,
        setCurrentValue: setCurrentBcrCase
      })
    }
    else if(master.bcr_cases && !toggle["bcrCases"]) {
      return master.bcr_cases.map(item => `${item}, `)
    } else return 'None'
  }

  function renderBosCasesOrEdit() {
    if(toggle["bosCases"]) {
      return editArrayItem({
        typeOfEdit: "bos_cases",
        toggle: "bosCases",
        label: 'BOS Case',
        values: bosCases,
        currentValue: currentBosCase,
        setValues: setBosCases,
        setCurrentValue: setCurrentBosCase
      })
    }
    else if(master.bos_cases && !toggle["bosCases"]) {
      return master.bos_cases.map(item => `${item}, `)
    } else return 'None'
  }

  function renderBcrCommentaryCasesOrEdit() {
    if(toggle["bcrCommentaryCases"]) {
      return editArrayItem({
        typeOfEdit: "bcr_commentary_cases",
        toggle: "bcrCommentaryCases",
        label: 'BCR Commentary Case',
        values: bcrCommentaryCases,
        currentValue: currentBcrCommentaryCase,
        setValues: setBcrCommentaryCases,
        setCurrentValue: setCurrentBcrCommentaryCase
      })
    }
    else if(master.bcr_commentary_cases && !toggle["bcrCommentaryCases"]) {
      return master.bcr_commentary_cases.map(name => `${name}, `)
    } else return 'None'
  }

  function renderBosCommentaryCasesOrEdit() {
    if(toggle["bosCommentaryCases"]) {
      return editArrayItem({
        typeOfEdit: "bos_commentary_cases",
        toggle: "bosCommentaryCases",
        label: 'BOS Commentary Case',
        values: bosCommentaryCases,
        currentValue: currentBosCommentaryCase,
        setValues: setBosCommentaryCases,
        setCurrentValue: setCurrentBosCommentaryCase
      })
    }
    else if(master.bos_commentary_cases && !toggle["bosCommentaryCases"]) {
      return master.bos_commentary_cases.map(name => `${name}, `)
    } else return 'None'
  }

  function renderWumenguanOrEdit() {
    if(toggle["wumenguan"]) {
      return editArrayItem({
        typeOfEdit: "wumenguan",
        toggle: "wumenguan",
        label: 'Wumenguan',
        values: wumenguan,
        currentValue: currentWumenguan,
        setValues: setWumenguan,
        setCurrentValue: setCurrentWumenguan
      })
    }
    else if(master.wumenguan && !toggle["wumenguan"]) {
      return master.wumenguan.map(item => `${item}, `)
    } else return 'None'
  }

  function renderMeasuringTapOrEdit() {
    if(toggle["measuringTap"]) {
      return editArrayItem({
        typeOfEdit: "measuring_tap",
        toggle: "measuringTap",
        label: 'Measuring Tap',
        values: measuringTap,
        currentValue: currentMeasuringTap,
        setValues: setMeasuringTap,
        setCurrentValue: setCurrentMeasuringTap
      })
    }
    else if(master.measuring_tap && !toggle["measuringTap"]) {
      return master.measuring_tap.map(item => `${item}, `)
    } else return 'None'
  }

  function renderTotettOrEdit() {
    if(toggle["totett"]) {
      return editArrayItem({
        typeOfEdit: "totett",
        toggle: "totett",
        label: 'Treasury of the eye of true teaching',
        values: totett,
        currentValue: currentTotett,
        setValues: setTotett,
        setCurrentValue: setCurrentTotett
      })
    }
    else if(master.totett && !toggle["totett"]) {
      return master.totett.map(item => `${item}, `)
    } else return 'None'
  }

  function editDharmaTeacher() {
    function handleEditDharmaTeacher(event) {
      const params = {
        dharma_teacher_id: dharmaTeacher[0].id
      }
      editMaster({params: params, typeOfEdit: 'dharma_teacher_id', toggle: 'dharmaTeacher'})
    }

    if(masters) {
      return(
        <div className='d-flex flex-row align-items-end'>
          <Multiselect
            options={masters}
            onSelect={(selectedList, selectedItem) => setDharmaTeacher([{display_name: selectedItem.display_name, id: selectedItem.id}])}
            displayValue='display_name'
            placeholder='Dharma Teacher'
            selectionLimit={1}
            selectedValues={dharmaTeacher}
          />
          <Button variant="link" onClick={(event) => handleEditDharmaTeacher(event)}>Complete</Button>
        </div>
      )
    }
  }

  function renderOtherTeachersOrEdit() {

    master.other_teachers.map(teacher => {
      const displayName = masters.filter((master) => (master.id === teacher.master_id))[0].display_name
      teacher.display_name = displayName
    })

    function handleEditOtherTeachers(event) {
      const params = {
        other_teachers: otherTeachers
      }
      editMaster({params: params, typeOfEdit: 'other_teachers', toggle: 'otherTeachers'})
    }

    if(toggle["otherTeachers"]) {
      return(
        <div className='d-flex flex-row align-items-end'>
          <Multiselect
            options={masters}
            onSelect={(selectedList, selectedItem) => setOtherTeachers(selectedList)}
            onRemove={(selectedList, selectedItem) => setOtherTeachers(selectedList)}
            displayValue='display_name'
            placeholder='Other Teachers'
            selectedValues={otherTeachers}
          />
          <Button variant="link" onClick={(event) => handleEditOtherTeachers(event)}>Complete</Button>
        </div>
      )
    }
    else if(master.other_teachers && !toggle["otherTeachers"]) {
      return master.other_teachers.map(teacher => {
        return `${teacher.display_name}, `})
    } else return 'None'
  }

  function renderNoteworthyStudentsOrEdit() {

    master.noteworthy_students.map(student => {
      const displayName = masters.filter((master) => (master.id === student.student_id))[0].display_name
      student.display_name = displayName
    })

    function handleEditNoteworthyStudents(event) {
      const params = {
        noteworthy_students: noteworthyStudents
      }
      editMaster({params: params, typeOfEdit: 'noteworthy_students', toggle: 'noteworthyStudents'})
    }

    if(toggle["noteworthyStudents"]) {
      return(
        <div className='d-flex flex-row align-items-end'>
          <Multiselect
            options={masters}
            onSelect={(selectedList, selectedItem) => setNoteworthyStudents(selectedList)}
            onRemove={(selectedList, selectedItem) => setNoteworthyStudents(selectedList)}
            displayValue='display_name'
            placeholder='Noteworthy Student'
            selectedValues={noteworthyStudents}
          />
          <Button variant="link" onClick={(event) => handleEditNoteworthyStudents(event)}>Complete</Button>
        </div>
      )
    }
    else if(master.noteworthy_students && !toggle["noteworthyStudents"]) {
      return master.noteworthy_students.map(student => {
        return `${student.display_name}, `})
    } else return 'None'
  }

  function renderMasterInfo() {
    if(masters && master) {
      return(
        <Row>
          <Col sm={8}>
            <h3 className='text-center'>{master.display_name}</h3>
            <div>
              <h4>Names:</h4>
              <p>
                Display Name: {toggle["displayName"] ? editSingleItem({typeOfEdit: "display_name", toggle: "displayName", inputType: 'text'}) : master.display_name} <Button onClick={() => handleToggle("displayName")} variant="link">{toggle["displayName"] ? '- Edit' : '+ Edit'}</Button>
              </p> 
              <p>
                Original Chinese Name: {toggle["originalChineseName"] ? editSingleItem({typeOfEdit: "original_chinese_name", toggle: "originalChineseName", inputType: 'text'}) : master.original_chinese_name} <Button onClick={() => handleToggle("originalChineseName")} variant="link">{toggle["originalChineseName"] ? '- Edit' : '+ Edit'}</Button>
              </p>
              <p>
                Wade Giles Name: {toggle["wadeGilesName"] ? editSingleItem({typeOfEdit: "wade_giles_name", toggle: "wadeGilesName", inputType: 'text'}) : master.wade_giles_name} <Button onClick={() => handleToggle("wadeGilesName")} variant="link">{toggle["wadeGilesName"] ? '- Edit' : '+ Edit'}</Button>
                </p>
              <p>
                Japanese Name: {toggle["japaneseName"] ? editSingleItem({typeOfEdit: "japanese_name", toggle: "japaneseName", inputType: 'text'}) : master.japanese_name} <Button onClick={() => handleToggle("japaneseName")} variant="link">{toggle["japaneseName"] ? '- Edit' : '+ Edit'}</Button>
              </p>
              <p>
                Alternative Names: {renderAlternativeNamesOrEdit()} <Button onClick={() => handleToggle("alternativeNames")} variant="link">{toggle["alternativeNames"] ? '- Edit' : '+ Edit'}</Button>
              </p>
            </div>
            <div>
              <h4>Other Info:</h4>
              <p>
                Generation: {toggle["generation"] ? editSingleItem({typeOfEdit: "generation", toggle: "generation", inputType: 'number'}) : master.generation} <Button onClick={() => handleToggle("generation")} variant="link">{toggle["generation"] ? '- Edit' : '+ Edit'}</Button>
              </p>
              <p>
                Dates: {toggle["dates"] ? editSingleItem({typeOfEdit: "dates", toggle: "dates", inputType: 'text'}) : master.dates} <Button onClick={() => handleToggle("dates")} variant="link">{toggle["dates"] ? '- Edit' : '+ Edit'}</Button>
              </p>
              <p>
                School: {toggle["school"] ? editSingleItem({typeOfEdit: "school", toggle: "school", inputType: 'text'}) : master.school} <Button onClick={() => handleToggle("school")} variant="link">{toggle["school"] ? '- Edit' : '+ Edit'}</Button>
              </p>
              <p>
                Biography: {toggle["biography"] ? editSingleItem({typeOfEdit: "biography", toggle: "biography", inputType: 'textarea'}) : master.biography} <Button onClick={() => handleToggle("biography")} variant="link">{toggle["biography"] ? '- Edit' : '+ Edit'}</Button>
              </p>
            </div>
            <div>
              <h4>Teachers/Students:</h4>
              <p>
                Dharma Teacher: {toggle["dharmaTeacher"] ? editDharmaTeacher() : master.dharma_teacher_display_name} <Button onClick={() => handleToggle("dharmaTeacher")} variant="link">{toggle["dharmaTeacher"] ? '- Edit' : '+ Edit'}</Button>
              </p>
              <p>
                Other Teachers: {renderOtherTeachersOrEdit()} <Button onClick={() => handleToggle("otherTeachers")} variant="link">{toggle["otherTeachers"] ? '- Edit' : '+ Edit'}</Button>
              </p>
              <p>
                Noteworthy Students: {renderNoteworthyStudentsOrEdit()} <Button onClick={() => handleToggle("noteworthyStudents")} variant="link">{toggle["noteworthyStudents"] ? '- Edit' : '+ Edit'}</Button>
              </p>
            </div>
            <div>
              <h4>BCR:</h4>
              <p>
                BCR Cases: {renderBcrCasesOrEdit()} <Button onClick={() => handleToggle("bcrCases")} variant="link">{toggle["bcrCases"] ? '- Edit' : '+ Edit'}</Button>
              </p>
              <p>
                BCR Commentary Cases: {renderBcrCommentaryCasesOrEdit()} <Button onClick={() => handleToggle("bcrCommentaryCases")} variant="link">{toggle["bcrCommentaryCases"] ? '- Edit' : '+ Edit'}</Button>
              </p>
            </div>
            <div>
              <h4>BOS:</h4>
              <p>
                BOS Cases: {renderBosCasesOrEdit()} <Button onClick={() => handleToggle("bosCases")} variant="link">{toggle["bosCases"] ? '- Edit' : '+ Edit'}</Button>
              </p>
              <p>
                BOS Commentary Cases: {renderBosCommentaryCasesOrEdit()} <Button onClick={() => handleToggle("bosCommentaryCases")} variant="link">{toggle["bosCommentaryCases"] ? '- Edit' : '+ Edit'}</Button>
              </p>
            </div>
            <div>
              <h4>Other Texts:</h4>
              <p>
                Wumenguan: {renderWumenguanOrEdit()} <Button onClick={() => handleToggle("wumenguan")} variant="link">{toggle["wumenguan"] ? '- Edit' : '+ Edit'}</Button>
              </p>      
              <p>
                Treasury of the Eye of True Teaching: {renderTotettOrEdit()} <Button onClick={() => handleToggle("totett")} variant="link">{toggle["totett"] ? '- Edit' : '+ Edit'}</Button>
              </p>      
              <p>
                Measuring Tap: {renderMeasuringTapOrEdit()} <Button onClick={() => handleToggle("measuringTap")} variant="link">{toggle["measuringTap"] ? '- Edit' : '+ Edit'}</Button>
              </p>      
            </div>
          </Col>
        </Row>
      )
    }
  }

  return (
    <div>
      {renderMasterInfo()}
    </div>
  )
}
