import React, { Component } from 'react'
import { getElastic as getElasticApiCall} from '../config/searchApi'
import SingleResult from '../components/SingleResult'
import { sourceTextTitle } from '../config/sourceTextUtils'
import { Stack, Row, Col, Button, Alert } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'

function prepareMarkdownText(text) {
  const newlineRegex = new RegExp("\\n", "g");
  const doubleQuotesRegex = new RegExp(">>", "g");
  const finalQuoteRegex = new RegExp("(>\\n)*>$", "g");
  let fixedText = ">" + text;

  fixedText = fixedText.replace(newlineRegex, "\n>\n>");
  fixedText = fixedText.replace(doubleQuotesRegex, ">");
  fixedText = fixedText.replace(finalQuoteRegex, "");

  return fixedText;
}

class Single extends Component {
  constructor(props) {
    super(props)

    let urlParams = new URLSearchParams(window.location.search)
    this.id = urlParams.get('id')
    this.index = urlParams.get('index')

    this.state = {
      result: null,
      error: null,
      fetching: false,
      showCopied: false
    }

    if(this.id && this.index) {
      this.getData()
    }
  }

  getData = () => {
    this.setState({fetching: true})
    let res = getElasticApiCall(this.id, this.index)

    res
      .then(data => {
        const item = data.data
        this.setState({
          result: {
              index: item._index,
              id: item._id,
              name: item._source.name,
              case: item._source.case,
              title: sourceTextTitle(item._index) + ' #' + item._id + (item._id === item._source.name ? '' : ': ' + item._source.name),
              collapsed: false
          },
          error: null,
          fetching: false
        })
      })
      .catch(error => {
        this.setState({
          result: null,
          error: error,
          fetching: false
        })
      })
  }

  writeToClipboard = (text) => {
    if(navigator && navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
        // success
        this.setState({showCopied: true})
      }).catch((err) => {
        // fail
      })
    } else {
      // no clipboard
    }
  }

  copyText = () => {
    this.writeToClipboard(this.state.result.case)
  }

  copyMarkdown = () => {
    this.writeToClipboard(prepareMarkdownText(this.state.result.case))
  }

  renderResult() {
    if(this.state.error) {
      return (<Row><Col className="text-center">{this.state.error.message}</Col></Row>)
    }
    if(this.state.fetching) {
      return (<Row><Col className="text-center">Fetching data...</Col></Row>)
    }

    if(this.state.result) {
      return (
        <SingleResult item={this.state.result} />
      )
    }

    return (<></>)
  }

  edit = () => {
    this.props.history.push({
      pathname: '/single-edit',
      search: `?id=${this.id}&index=${this.index}`
    })
  }

  render() {
    return (
      <Stack gap={3}>
        <Row>&nbsp;</Row>
        {this.renderResult()}
        <Row className="justify-content-center">
          <Col sm={10} className="text-center">
            <Button variant="outline-secondary" onClick={this.copyText} disabled={!this.state.result} >Copy Text</Button>{' '}
            <Button variant="outline-secondary" onClick={this.copyMarkdown} disabled={!this.state.result} >Copy Markdown</Button>{' '}
            {this.props.showEdit ? <Button variant="outline-danger" onClick={this.edit} disabled={!this.state.result} >Edit</Button> : null}
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm={3} className="text-center">
            <Alert variant="success" show={this.state.showCopied} onClose={() => this.setState({showCopied: false})} dismissible>Copied!</Alert>
          </Col>
        </Row>
      </Stack>
    )
  }
}

export default withRouter(Single)