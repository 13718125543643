import React, { Component } from 'react'
import { Form, Row, Col, Stack, FormGroup, Button, FormLabel } from 'react-bootstrap'
import SearchResults from '../components/SearchResults'
import { caseTexts, sayingsTexts, allTexts, sourceTextTitle } from '../config/sourceTextUtils'
import { withRouter } from 'react-router-dom'

function ShowOptions(props) {
  if(!props.showOptions) {
    return (<> </>)
  } else {
    const {selectedTexts, toggleText} = props
    return (
      <Stack gap={3} id="options">
        <Row className="justify-content-center">
          <Col sm={{span: 4, offset: 1}}>
            <h5>Cases</h5>
            <FormGroup>
              {caseTexts.map(index => {
                return (
                  <Form.Check type="checkbox" className="index chk-cases" id={'chk_' + index} name={index} value={index} label={sourceTextTitle(index)} checked={selectedTexts.includes(index)} onChange={toggleText} />
                )
              })}
            </FormGroup>
          </Col>
          <Col sm={5}>
            <h5>Sayings Texts</h5>
            <FormGroup>
              {sayingsTexts.map(index => {
                return (
                  <Form.Check type="checkbox" className="index chk-sayings" id={'chk_' + index} name={index} value={index} label={sourceTextTitle(index)} checked={selectedTexts.includes(index)} onChange={toggleText} />
                )
              })}
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center align-items-center">
          <Col sm="auto" >
            <Button id="select-all-btn" variant="outline-secondary" onClick={props.selectAll}>Select All</Button>
          </Col>
          <Col sm="auto" >
            <Button id="select-none-btn" variant="outline-secondary" onClick={props.selectNone}>Clear Selection</Button>
          </Col>
          <Col sm="auto" >
            <Button id="select-cases-btn" variant="outline-secondary" onClick={props.selectCases}>Select Cases</Button>
          </Col>
          <Col sm="auto" >
            <Button id="select-invert-btn" variant="outline-secondary" onClick={props.invertSelection}>Invert Selection</Button>
          </Col>
          <Col sm="auto" className="border rounded">
            <FormLabel>Show:</FormLabel>{' '}
            <Form.Check inline type="radio" id="top-ten" name="resultsize" label="Top 10" value="10" defaultChecked={props.showTop === '10'} onChange={props.setShowTop} />
            <Form.Check inline type="radio" id="top-hundred" name="resultsize" label="Top 100" value="100" defaultChecked={props.showTop === '100'} onChange={props.setShowTop} />
          </Col>
        </Row>
      </Stack>
    )
  }
}

class Search extends Component {
  constructor(props) {
    super(props)

    let showTop = localStorage.getItem('showTop');

    let urlParams = new URLSearchParams(window.location.search)

    this.state = {
      showOptions: false,
      selectedTexts: [...allTexts],
      showTop: showTop === null ? '10' : showTop,
      queryText: urlParams.get('q')
    }

    this.text = this.state.queryText
  }

  componentDidMount() {
    this.oldPopstate = window.onpopstate
    window.onpopstate = (event) => {

      this.text = event.state ? event.state.state : ''
      if(this.searchField) this.searchField.value = this.text
      this.setState({
        queryText: this.text,
      })

      if(this.oldPopstate) {
        this.oldPopstate(event)
      }
    }
  }

  componentWillUnmount() {
    window.onpopstate = this.oldPopstate
  }
  
  setShowTop = (event) => {
    localStorage.setItem('showTop', event.target.value)
    this.setState({
      showTop: event.target.value
    })
  }

  toggleText = (event) => {
    const textId = event.target.value;
    const selectedTexts = this.state.selectedTexts.includes(textId) ? this.state.selectedTexts.filter(v => v !== textId) : [...this.state.selectedTexts, textId]
    this.setState({
      selectedTexts: selectedTexts
    })
  }

  toggleOptions = () => {
    this.setState({
      showOptions: !this.state.showOptions
    })
  }
  
  selectAll = () => {
    this.setState({
      selectedTexts: [...allTexts]
    })
  }

  selectNone = () => {
    this.setState({
      selectedTexts: []
    })
  }

  selectCases = () => {
    this.setState({
      selectedTexts: [...caseTexts]
    })
  }

  invertSelection = () => {
    this.setState({
      selectedTexts: allTexts.filter(v => !this.state.selectedTexts.includes(v))
    })
  }

  setText = (event) => {
    this.text = event.target.value
    this.searchField = event.target
  }

  searchClick = (event) => {
    event.preventDefault()
    event.stopPropagation();

    if(this.text) {
      this.props.history.push(`/search?q=${this.text}`, this.text)
      this.setState({
        queryText: this.text
      })
    }
  }

  render() {
    return (
        <Form id="search-form">
          <Row className="justify-content-center">
            <Col sm={10} className="text-center">
              <img src="zenmarrow_200x200.png" alt="" height="200" width="200" />
            </Col>
          </Row>
          <Stack gap={3}>
            <ShowOptions 
              showOptions={this.state.showOptions} 
              toggleText={this.toggleText} 
              showTop={this.state.showTop} 
              setShowTop={this.setShowTop} 
              selectedTexts={this.state.selectedTexts} 
              selectAll={this.selectAll}
              selectNone={this.selectNone}
              selectCases={this.selectCases}
              invertSelection={this.invertSelection}
              search={this.search}
            />
            <Row className="justify-content-center">
              <Col sm={10}>
                <Form.Control 
                  className="text-center" 
                  id="search" 
                  name="search" 
                  type="text" 
                  placeholder="What is the highest meaning of the holy truths?" 
                  autofocus="true" 
                  onChange={this.setText} 
                  defaultValue={this.state.queryText}
                />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col sm={10} className="text-center">
                <FormGroup>
                  <Button type="submit" variant="primary" onClick={this.searchClick}>Zen Text Search</Button>{' '}
                  <Button variant="outline-secondary" onClick={this.toggleOptions}>Options</Button>
                </FormGroup>
              </Col>
            </Row>
            <SearchResults queryText={this.state.queryText} selectedTexts={this.state.selectedTexts} showTop={this.state.showTop} />
          </Stack>
        </Form>
    )
  }
}

export default withRouter(Search)

//