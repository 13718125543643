import React from 'react'
import { Alert } from 'react-bootstrap'

export default function AlertCustom(props) {
  const {alertToggle, alertType, alertText} = props

  function renderAlert() {
    if(alertToggle) {
      return(
        <Alert className='mt-3' variant={alertType}>
          {alertText}
        </Alert>
      )
    }
  }

  return (
    <div>
      {renderAlert()}
    </div>
  )
}
