import React, { Component } from 'react'
import { getElastic as getElasticApiCall, saveText as saveTextApiCall} from '../config/searchApi'
import { sourceTextTitle } from '../config/sourceTextUtils'
import { Stack, Row, Col, Button, Alert, Form } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'

class SingleEdit extends Component {
  constructor(props) {
    super(props)

    let urlParams = new URLSearchParams(window.location.search)
    this.id = urlParams.get('id')
    this.index = urlParams.get('index')

    this.state = {
      result: null,
      error: null,
      fetching: false,
      saving: false,
      showSaved: false
    }

    if(this.id && this.index) {
      this.getData()
    }
  }

  getData = () => {
    this.setState({fetching: true})
    let res = getElasticApiCall(this.id, this.index)

    res
      .then(data => {
        const item = data.data
        this.text = item._source.case
        this.setState({
          result: {
              index: item._index,
              id: item._id,
              name: item._source.name,
              case: item._source.case,
              title: sourceTextTitle(item._index) + ' #' + item._id + (item._id === item._source.name ? '' : ': ' + item._source.name),
              collapsed: false
          },
          error: null,
          fetching: false
        })
      })
      .catch(error => {
        this.setState({
          result: null,
          error: error,
          fetching: false
        })
      })
  }

  setText = (event) => {
    this.text = event.target.value
  }

  saveText = (event) => {
    event.preventDefault()
    event.stopPropagation()

    let res = saveTextApiCall(this.id, this.index, this.state.result.name, this.text)

    res.then(data => {
      this.setState((state, props) => {
        state.result.case = this.text
        return {
          result: state.result,
          saving: false,
          error: null,
          showSaved: true
        }
      })  
    }).catch((error) => {
      this.setState({
        error: error,
        saving: false
      })
    })
  }

  renderResult() {
    if(this.state.error) {
      return (<Row><Col className="text-center">{this.state.error.message}</Col></Row>)
    }
    if(this.state.fetching) {
      return (<Row><Col className="text-center">Fetching data...</Col></Row>)
    }

    if(this.state.result) {
      return (
        <Form>
          <h5>{this.state.result.title}</h5>
          <Form.Control as="textarea" rows={20} cols={80} onChange={this.setText} defaultValue={this.state.result.case} />
        </Form>
      )
    }

    return (<></>)
  }

  render() {
    return (
      <Stack gap={3}>
        <Row>&nbsp;</Row>
        {this.renderResult()}
        <Row className="justify-content-center">
          <Col sm={10} className="text-center">
            <Button variant="outline-danger" onClick={this.saveText} disabled={!this.state.result} >Save</Button>{' '}
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm={3} className="text-center">
            <Alert variant="success" show={this.state.showSaved} onClose={() => this.setState({showSaved: false})} dismissible>Saved!</Alert>
          </Col>
        </Row>
      </Stack>
    )
  }
}

export default withRouter(SingleEdit)