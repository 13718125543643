import React, { Component } from 'react'
import { Stack } from 'react-bootstrap'

export default class Contact extends Component {
  render() {
    return (
      <Stack gap={3}>
        <h1>Get In Touch</h1>
        <em>Email</em>
        <p>
            You can reach us via email at <a href="mailto:sanctuariaeve@gmail.com">sanctuariaeve@gmail.com</a>.
        </p>
        <em>Reddit</em>
        <p>
            You can find us on Reddit at <a href="https://reddit.com/r/zenmarrow">r/zenmarrow</a> and <a href="https://reddit.com/r/knotzen">r/knotzen</a>.
        </p>
        <em>Discord</em>
        <ul>
            <li><a href="https://discord.gg/Rxx82hM">Into the Weeds</a> - where we hang out and talk about Zen and other topics</li>
            <li><a href="https://discord.gg/sgckF9Tj2y">Sumaru</a> - more focussed on development of projects like ZenMarrow</li>
        </ul>
      </Stack>
    )
  }
}