import React from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { requestResetEmail as requestResetEmailAPICall } from '../config/api'

export default function ForgotPassword(props) {

  async function handleSubmit(event) {
    const {setAlertToggle, setAlertType, setAlertText} = props.alertSettings

    event.preventDefault()
    const email = event.target.email.value.trim()
    try {
      const res = await requestResetEmailAPICall(email)
      if(res.status === 200) {
        setAlertType('success')
        setAlertText('Sent! Please check your email for instruction.')
        setAlertToggle(true)
      }
    } catch(err) {
      if(err.response && err.response.status === 400) {
        setAlertType('warning')
        setAlertText('No user found with this email.')
        setAlertToggle(true)
      } else {
        setAlertType('danger')
        setAlertText('Something Went Wrong, Please Try Again Later.')
        setAlertToggle(true)
      }
    }
  }
  return (
    <Row>
      <Col sm={6}>
        <h2 className="pb-3">Forgot Password</h2>
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Form.Group controlId="email">
            <Form.Label>Enter your email to receive password reset link</Form.Label>
            <Form.Control 
              required
              size="md"
              type="text"
              placeholder="Email" />
          </Form.Group>
          <br />
          <Button variant="outline-secondary" type="submit">
            Submit
          </Button>
        </Form>
      </Col>
    </Row>
  )
}