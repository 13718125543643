import React from 'react'
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import { getCurrentUser as getCurrentUserAPICall } from './config/api'

import ZMNavbar from './components/ZMNavbar'

import Search from './views/Search';
import Single from './views/Single';
import SingleEdit from './views/SingleEdit';
import Contact from './views/Contact';
import SearchInfo from './views/SearchInfo';
import LineageChart from './views/LineageChart';
import Login from './views/Login';
import Logout from './views/Logout';
import ResetPassword from './views/ResetPassword';
import ForgotPassword from './views/ForgotPassword';
import NoMatch from './views/NoMatch';
import AccessDenied from './views/AccessDenied';
import Masters from './views/Masters';
import AdminTools from './views/AdminTools';

import { Container } from 'react-bootstrap';
import AlertCustom from './components/Alert';
import EditMaster from './views/EditMaster';
import Register from './views/Register';
import Account from './views/Account';
import AdminUsers from './views/AdminUsers';

class Root extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      authenticated: false,
      userIsAdmin: false,
      userId: null,
      loadingAuth: false,
      hideSideBar: false,
      alertToggle: false,
      alertType: 'warning',
      alertText: 'Something went wrong, please try again.'
    }
  }

  componentDidMount() {
    // authenticate
    this.authenticate()
  }

  componentDidUpdate() {
    if (this.state.alertToggle) {
      setTimeout(() => {
        this.setState({
          alertToggle: false
        })
      }, 8000)
    }
  }

  setAlertToggle(state) {
    this.setState({ alertToggle: state })
  }

  setAlertType(type) {
    this.setState({ alertType: type })
  }

  setAlertText(text) {
    this.setState({ alertText: text })
  }

  authenticate = () => {
    this.setState({
      loadingAuth: true
    }, async () => {
        getCurrentUserAPICall().then((data) => {
          console.log(data)
          if (data.status === 200) {
            this.setState({
              authenticated: true,
              loadingAuth: false,
              userIsAdmin: data.data.admin,
              userId: data.data.id
            })
          } else throw new Error(data)
        }).catch((err) => {
          console.log(err)
          this.setState({
            authenticated: false,
            loadingAuth: false,
            userIsAdmin: false,
            userId: null
          })
        })
      }
    )
  }


  render() {
    const { authenticated, userIsAdmin, userId, loadingAuth, alertToggle, alertType, alertText } = this.state
    const alertSettings = {
      setAlertToggle: (state) => this.setAlertToggle(state),
      setAlertType: (type) => this.setAlertType(type),
      setAlertText: (text) => this.setAlertText(text)
    }
    const urlParams = new URLSearchParams(window.location.search);
    return (
      <Container>
        <ZMNavbar authenticated={authenticated} userIsAdmin={userIsAdmin} />
        <AlertCustom
          alertToggle={alertToggle}
          alertType={alertType}
          alertText={alertText}
        />
        <Switch>
          <Route exact path="/">
            <Redirect to={{ pathname: '/search', search: urlParams.get('q') ? `q=${urlParams.get('q')}` : '' }} />
          </Route>
          <Route path="/search">
            <Search />
          </Route>
          <Route path="/single">
            <Single showEdit={authenticated && userIsAdmin} />
          </Route>
          <Route path="/single-edit">
            {authenticated && userIsAdmin ? <SingleEdit /> : <AccessDenied loadingAuth={loadingAuth} />}
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/search-info">
            <SearchInfo />
          </Route>
          <Route path="/lineage-chart">
            <LineageChart />
          </Route>
          <Route path="/register">
            <Register alertSettings={alertSettings}/>
          </Route>
          <Route path="/account">
            <Account alertSettings={alertSettings}/>
          </Route>
          <Route path="/admin/users">
            {authenticated && userIsAdmin ? <AdminUsers userId={userId} /> : <AccessDenied loadingAuth={loadingAuth} />}
          </Route>
          <Route path="/admin/masters">
            <Masters userIsAdmin={userIsAdmin}/>
          </Route>
          <Route path="/logout">
            <Logout authenticate={this.authenticate} />
          </Route>
          <Route path="/login">
            <Login
              authenticate={this.authenticate}
              authenticated={authenticated}
              loadingAuth={loadingAuth}
              alertSettings={alertSettings}
            />
          </Route>
          <Route path="/forgot_password">
            <ForgotPassword alertSettings={alertSettings} />
          </Route>
          <Route path="/admin/reset_password/:token">
            <ResetPassword alertSettings={alertSettings} />
          </Route>
          <Route path="/admin/tools">
            {authenticated && userIsAdmin ? <AdminTools alertSettings={alertSettings} /> : <AccessDenied loadingAuth={loadingAuth} />}
          </Route>
          <Route path="/admin/edit/:master">
            {authenticated && userIsAdmin ? <EditMaster alertSettings={alertSettings} /> : <AccessDenied loadingAuth={loadingAuth} />}
          </Route>
          <Route render={() => <NoMatch />} />
        </Switch>
      </Container>
    )
  }
}

export default Root
