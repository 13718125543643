import React from 'react'
import {Form, Button} from 'react-bootstrap'

export default function MultiItemInput(props) {
  const {hasLabel, label, type, values, currentValue, setValues, setCurrentValue} = props

  function handleAddValue({event, values, currentValue, setValues, setCurrentValue}) {
    event.preventDefault()
    if(!currentValue.trim().length) return
    setValues([...values, currentValue.trim()])
    setCurrentValue('')
  }
  function handleRemoveValue({value, values, setValues}) {
    const newArray = values.filter(oldValue => oldValue !== value)
    setValues(newArray)
  }

  return (
    <Form 
      onSubmit={(event) => handleAddValue({
        event: event, 
        values: values, 
        currentValue: currentValue, 
        setValues: setValues, 
        setCurrentValue: setCurrentValue
        })}
    >
      <Form.Group>
        {hasLabel ? <Form.Label>{label}</Form.Label> : null}
        <div className='pl-3 d-flex flex-row flex-wrap'>
          {values.map(value => (
            <p className='mr-3 mb-1 d-flex'>
              {value} 
              <Button 
                variant='link' 
                className='m-0 p-0 align-self-start' 
                style={{color: 'red', fontSize: 12}} 
                onClick={() => handleRemoveValue({
                  value: value,
                  values: values,
                  setValues: setValues
                })}>
                X
              </Button>
              </p>
            ))}
        </div>
        <Form.Control 
          size="sm"
          type={type ? type : 'text'}
          placeholder={label} 
          value={currentValue}
          onChange={(event) => setCurrentValue(event.target.value)}
        />
      </Form.Group>
      <Button variant="link" type="submit">
        + {label}
      </Button>
    </Form>
  )
}
