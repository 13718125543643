import React, { Component } from 'react'

export default class NoMatch extends Component {
  render() {
    return (
      <div className="w-50 d-flex flex-column justify-content-center">
        <h1>No Match</h1>
      </div>
    )
  }
}