import React, { Component, createRef } from 'react'
import { getMasters as getMastersApiCall } from '../config/api'
import { Stack, Container, Form, FormSelect, FormGroup, FormLabel, Button, Row, Col } from 'react-bootstrap'
import { OrgChart } from '../config/d3-org-chart'
import * as d3 from 'd3';
import { withRouter } from 'react-router-dom'

const directions = ['right', 'bottom', 'left', 'top'];
function hue(school) {
  let v = 0;
  for(let i = 0; i < school.length; i++) {
      v += (school.charCodeAt(i) - 64) * 19;
  }
  return v % 360;
}

function linkColor(school) {
  let v = hue(school);
  return 'hsl(' + v + ', 100%, 70%)';
}

function bgColor(school) {
  let v = hue(school);
  return 'hsl(' + v + ', 50%, 95%)';
}

function textColor(school) {
  let v = hue(school);
  return 'hsl(' + v + ', 100%, 30%)';
}

class LineageChart extends Component {
  constructor(props) {
    super(props)

    let urlParams = new URLSearchParams(window.location.search)

    this.state = {
      masters: null,
      selectedMasterId: urlParams.get('m') || '16', // The big man Bodhidharma
      isFullScreen: false
    }

    this.chartRef = createRef()

    this.compact = true
    this.direction = 3
  }

  processData(masters) {
    for(let i = 0; i < masters.length; i++) {
      masters[i].id = '' + masters[i].id
      masters[i].parentId = masters[i].dharma_teacher_id ? '' + masters[i].dharma_teacher_id : null
      masters[i].name = masters[i].display_name
      masters[i].chin = masters[i].original_chinese_name
      masters[i].bcrCases = masters[i].bcr_cases || []
      masters[i].ggCases = masters[i].wumenguan || []
      masters[i].bosCases = masters[i].bos_cases || []
      masters[i].toteottCases = masters[i].totett || []
      masters[i].mtCases = masters[i].measuring_tap || []
      masters[i].evCases = masters[i].evc || []
      masters[i].jp = masters[i].japanese_name
      masters[i].wg = masters[i].wade_giles_name
      masters[i].date = masters[i].dates
    }
    masters.sort((a, b) => (a.name < b.name ? -1 : 1))
  }

  renderChart = () => {
  
    this.processData(this.state.masters)

    this.chart = new OrgChart()
    .container(this.chartRef.current)
    .data(this.state.masters) 
    .nodeHeight(d => {
        let linkRows = 0;
        if(d.data.ggCases.length) linkRows++;
        if(d.data.bcrCases.length) linkRows++;
        if(d.data.bosCases.length) linkRows++;
        if(d.data.toteottCases.length) linkRows++;
        if(d.data.mtCases.length) linkRows++;
        if(d.data.evCases.length) linkRows++;
        return 100 + (d.data.jp ? 10 : 0) + (d.data.wg ? 10 : 0)+ (linkRows > 0 ? 20 : 0) + linkRows * 15;
    })
    .nodeWidth(d => 300)
    .nodeContent(data => {
        let zm = data.data;

        let soundLink = ''
        if(zm.sound_file) {
          soundLink = `<i class="fas fa-volume-up ch-sound" onclick="new Audio('sounds/${zm.sound_file}').play()"></i>`
        }
        
        return `<div class="node""> 
                    <div class="name section">
                        <div class="full-name">${zm.name}</div>
                        <div class="ch-name">${zm.chin}${soundLink}</div>
                        <div class="date">${zm.date}</div>
                    </div>
                    <div class="details section">
                        ${zm.wg ? `<div class="wg-name">${zm.wg}</div>` : ''}
                        ${zm.jp ? `<div class="jp-name">${zm.jp}</div>` : ''}
                    </div>
                    <div class="school section">
                        <div class="school"><span style="color:${textColor(zm.school)}">${zm.school}</span></div>
                    </div>
                    <div class="links section">
                        <div class="gg">
                            ${zm.ggCases.length ? '<span class="book-label">GG</span>' : ''}
                            ${
                                zm.ggCases.map((v) => {
                                    return `<a href="/Single?id=${v}&index=mmk" class="book-link" target="_blank">${v}</a>`
                                }).join(' ')
                            }
                        </div>
                        <div class="bcr">
                            ${zm.bcrCases.length ? '<span class="book-label">BCR</span>' : ''}
                            ${
                                zm.bcrCases.map((v) => {
                                    return `<a href="/Single?id=${v}&index=bcr" class="book-link" target="_blank">${v}</a>`
                                }).join(' ')
                            }
                        </div>
                        <div class="bos">
                            ${zm.bosCases.length ? '<span class="book-label">BoS</span>' : ''}
                            ${
                                zm.bosCases.map((v) => {
                                    return `<a href="/Single?id=${v}&index=bos" class="book-link" target="_blank">${v}</a>`
                                }).join(' ')
                            }
                        </div>
                        <div class="toteott">
                            ${zm.toteottCases.length ? '<span class="book-label">TotEoTT</span>' : ''}
                            ${
                                zm.toteottCases.map((v) => {
                                    return `<a href="/Single?id=${v}&index=sho" class="book-link" target="_blank">${v}</a>`
                                }).join(' ')
                            }
                        </div>
                        <div class="mt">
                            ${zm.mtCases.length ? '<span class="book-label">MT</span>' : ''}
                            ${
                                zm.mtCases.map((v) => {
                                    return `<a href="/Single?id=${v}&index=mt" class="book-link" target="_blank">${v}</a>`
                                }).join(' ')
                            }
                        </div>
                        <div class="ev">
                            ${zm.evCases.length ? '<span class="book-label">EV</span>' : ''}
                            ${
                                zm.evCases.map((v) => {
                                    return `<a href="/Single?id=${v}&index=ev" class="book-link" target="_blank">${v}</a>`
                                }).join(' ')
                            }
                        </div>
                    </div>
                </div>`;
    })
    .buttonContent(({ node, state }) => {
        return `<div style="color:${textColor(node.data.school)};border-radius:5px;padding:3px;font-size:10px;margin:auto auto;background-color:#ffffff;border: 1px solid ${linkColor(node.data.school)}"> <span style="font-size:9px">${
          node.children
            ? `<i class="fas fa-angle-up"></i>`
            : `<i class="fas fa-angle-down"></i>`
        }</span> ${node.data._directSubordinates}  </div>`;
    })
    .nodeUpdate(function (d, i, arr) {
        d3.select(this)
          .select('.node-rect')
          .style('fill', (d) => bgColor(d.data.school))
          .attr('stroke', (d) =>
            // d.data._highlighted || d.data._upToTheRootHighlighted
            //   ? '#14760D'
            //   : '#000000'
            linkColor(d.data.school)
          )
          .attr(
            'stroke-width',
            d.data._highlighted || d.data._upToTheRootHighlighted ? 8 : 1
          );
    })
    .linkUpdate(function (d, i, arr) {
        d3.select(this)
          .attr('stroke', (d) =>
            //d.data._upToTheRootHighlighted ? '#14760D' : '#2CAAE5'
            linkColor(d.data.school)
          )
          .attr('stroke-width', (d) =>
            d.data._upToTheRootHighlighted ? 12 : 3
          );

        if (d.data._upToTheRootHighlighted) {
          d3.select(this).raise();
        }
    })
    .render()
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      let h = this.state.isFullScreen ? 0 : 180;
      this.chartRef.current.style.height = '' + (window.innerHeight - h) + 'px';
    })
    
    window.addEventListener('fullscreenchange', () => {
      let h = this.state.isFullScreen ? 0 : 180;
      this.chartRef.current.style.height = '' + (window.innerHeight - h) + 'px';
    })

    window.addEventListener('popstate', (event) => {
      let selectId = event.state ? event.state.state : '16'

      this.setState({ selectedMasterId: selectId });
      
      this.chart.clearHighlighting();

      this.chart
        .collapseAll()
        .setExpanded(selectId)
        .setUpToTheRootHighlighted(selectId)
        .render()
        .fit();        
    });
    
    if(!this.state.masters) {
      getMastersApiCall().then((data) => {
        this.setState({
          masters: data.data
        })
        
      this.chart.clearHighlighting();

      this.chart
        .collapseAll()
        .setExpanded(this.state.selectedMasterId)
        .setUpToTheRootHighlighted(this.state.selectedMasterId)
        .render()
        .fit();        
      }).catch(err => {
        console.log(err);
      })
    }
  }

  expandAll = () => {
    this.chart.expandAll().fit()
  }

  toggleCompact = () => {
    this.compact = !this.compact
    this.chart.compact(this.compact).render().fit()
  }

  swap = () => {
    this.direction = (this.direction + 1) % 4
    this.chart.layout(directions[this.direction]).render().fit()
  }

  masterSelectChanged = (event) => {
    let selectedMasterId = event.target.value
    this.chart.clearHighlighting()

    this.chart
        .collapseAll()
        .setExpanded(selectedMasterId)
        .setUpToTheRootHighlighted(selectedMasterId)
        .render()
        .fit()

    this.setState({selectedMasterId: selectedMasterId})
    
    this.props.history.push(`/lineage-chart?m=${selectedMasterId}`, selectedMasterId)
  }

  renderLineageChartControls() {
    if(this.state.masters) {
      this.renderChart()

      return(
        <Form>
          <Row>
            <Col sm={2}>
              <span>Go to:</span>
            </Col>
            <Col sm={4}>
              <FormSelect onChange={this.masterSelectChanged}>
                { this.state.masters.map((master, i) => (<option key={i} value={master.id} selected={master.id===this.state.selectedMasterId}>{master.name}</option>)) }
              </FormSelect>
            </Col>
            <Col sm={6}>
              <Button onClick={this.expandAll}>Expand All</Button>{' '}
              <Button onClick={this.toggleCompact}>Compact</Button>{' '}
              <Button onClick={this.swap}>Swap</Button>{' '}
              <Button onClick={e => this.chart.fullscreen()}>Fullscreen</Button>
            </Col>
          </Row>
        </Form>
      )
    }
  }

  render() {
    return (
      <Stack gap={3}>
        <div></div>
        {this.renderLineageChartControls()}
        <Container fluid className="chart-container" ref={this.chartRef} />
      </Stack>
    )
  }
}

export default withRouter(LineageChart)
